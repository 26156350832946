<template>
  <div class="row no-gutters">
    <div class="col-12">
      <div class="row no-gutters">
        <div class=" col-12 offset-0">
          <div class="m-card text-center">
            <div class="row no-gutters">
              <div class="col-lg-8 offset-lg-2">
                <h1 class="o-heading o-heading--xl">
                  {{ $t(service==="mdl"?"mdl.referti.share_title":"referti.share.title") }}
                </h1>
                <p>{{$t(service==="mdl"?"mdl.referti.share_subtitle": "referti.share.subtitle") }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {  },
  name: "AppRefShareHead",
  props: ["userData","service"],

  data() {
    return {
    };
  },
  computed: {
  },

  methods: {
  },
};
</script>
