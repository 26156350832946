<template>
  <div class="row no-gutters">
    <div class="col-12 mt-5">
      <div class="row no-gutters">
        <div class=" col-12 offset-0">
          <div class="m-card text-center">
            <div class="row no-gutters">
              <div class="col-lg-8 offset-lg-2">
                <h1 class="o-heading o-heading--xl">
                  {{ $t("referti.share.title_2") }}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {  },
  name: "AppRefShareHead2",
  props: ["userData"],

  data() {
    return {
    };
  },
  computed: {
  },

  methods: {
  },
};
</script>
