<template>
  <div class="referti-share">
    <div class="row no-gutters">
      <div class="col-10 offset-1">
        
        <app-ref-share-head :service="service"></app-ref-share-head>

        <div class="row no-gutters">
          <div class="col-12 py-5">
            <div>
              <p class="mb-3">{{ $t("referti.share.p_1") }}</p>
              <p class="mb-3">{{ $t("referti.share.p_2") }}</p>
              <p class="mb-3">{{ $t("referti.share.p_3") }}</p>
            </div>
          </div>
        </div>
       
        <div class="o-ref-list o-ref-list--table">
         
          <div class="o-ref-list__head">
            <div class="o-ref-list__inner">
              <div class="o-ref-list--data">
                {{ $t("referti.tab_0") }}
              </div>        
              <div class="o-ref-list--name">
                {{ $t("referti.tab_1") }}
              </div>              
              <div class="o-ref-list--name">
                {{ $t("referti.tab_2") }}
              </div>
              <div class="o-ref-list--name">
              {{ $t("referti.tab_2A") }}
              </div>
            </div>
          </div>            

          <div class="m-ref-card m-ref-card--line d-block">
            <div class="m-ref-card__container m-ref-card__container--active">
              <div class="m-ref-card__inner py-2">
                <div class="m-ref-card__data">
                  {{ this.dataFormatted(this.referto.DataPrelievo) }}
                </div>
                <div class="m-ref-card__text--name">
                  {{ this.tipoReferto(this.referto.Sorgente) }}
                </div>                
                <div class="m-ref-card__text--name">
                  {{ this.referto.Prestazione }}
                </div>
                <div class="m-ref-card__text--name">
                  {{ this.referto.Episodio }}
                </div>
              </div>
            </div>
          </div>     

        </div>

        <div class="row no-gutters mb-5">

          <div class="col-12 offset-0 col-md-6 offset-md-3">            
            <b-alert class="mt-3" :variant="this.sharedErr ? 'danger' : 'success'" v-model="sharedMsg">{{ $t("referti.share.errors."+this.sharedMsgText) }}</b-alert>              
          </div>

          <div class="col-12 offset-0 col-md-6 offset-md-3">            

            <div class="m-card mt-3 text-center" v-if="!sharedMsg || sharedErr">

              <p>Specifica email del medico destinatario</p>

              <b-form-group v-if="false"
                  id="tel"
                  label-for="tel-input"
                  class="m-input m-input__tel mt-3"
              >
                  <span class="d-flex w-100">
                    <div class="icon-phone-check mr-3"></div>
                    <b-form-input
                        id="tel-input"
                        v-model="docTel"
                        type="tel"
                        :state="isPhone(docTel)"
                        :placeholder="this.$t('registration.tel')"
                        required
                    ></b-form-input>
                  </span>

                <b-form-invalid-feedback class="">
                  {{ $t("step.invalid_tel") }}
                </b-form-invalid-feedback>
              </b-form-group>




              <b-form-group
                  id="email"
                  label-for="email-input"
                  class="m-input m-input__icon"
              >
                <div class="icon-email mr-3"></div>
                <b-form-input
                    id="email-input"
                    v-model="docMail"
                    type="email"
                    :state="isEmail(docMail)"
                    :placeholder="this.$t('registration.email')"
                    aria-describedby="input-feedback-email"
                    required
                ></b-form-input>
              </b-form-group>

              <b-form-group v-if="false">
                <b-form-radio-group
                    id="radio-consenso"
                    v-model="consenso"
                    name="consenso"
                >
                  <b-form-radio value="true">{{
                      $t("referti.share.consenso")
                    }}
                  </b-form-radio>

                </b-form-radio-group>
              </b-form-group>

              <b-button variant="primary" size="md" class="text-uppercase mt-3" @click="inviaReferto()" :disabled="isEmail(docMail)===false || docMail==='' ">INVIA </b-button>
            
            </div>
          </div>
        </div>  

        <app-ref-share-head-2></app-ref-share-head-2>

        <div class="row no-gutters pt-5 mb-5">
          <div class="col-12">
            <b-table responsive borderless thead-class="primary" :items="sharedDocs" :fields="tbFields" v-if="sharedDocs.length>0"></b-table>
            <p v-else class="text-center">{{ ($t("referti.share.no_invii")) }}</p>
          </div>
        </div>  

        <div class="col-xl-6 offset-xl-3 col-10 offset-1 text-center">
          <b-button variant="outline-primary" class="text-uppercase" href="/referti">{{ $t("generic.back") }}</b-button>      
        </div>                              

      </div>
    </div>
  </div>
</template>

<script>
import AppRefShareHead from "../components/organisms/AppRefShareHead.vue";
import AppRefShareHead2 from "../components/organisms/AppRefShareHead2.vue";
import apiAxios from "@/libs/apiAxios";
export default {
  components: { AppRefShareHead, AppRefShareHead2 },
  name: "referti",

  created() {
    //this.loadData();
    this.getRefertiInviati();
  },

  data() {
    return {
      loading:true,
      referto:{},
      docTel:"",
      docMail:"",
      shareDoc:null,
      sharedMsg:null,
      sharedMsgText:"",
      sharedErr:null,
      sharedDocs:[],
      consenso:false,
      tbFields: [{key: 'referto.DataPrelievo', label: "ACCETTAZIONE", formatter: 'getDataIta'},
                 {key: 'referto.Sorgente', label: "TIPOLOGIA", formatter: 'tipoReferto'},
                 {key: 'referto.Prestazione', label: "PRESTAZIONE"},
                 {key: 'referto.Episodio', label: "EPISODIO"},
                 {key: 'email', label: "EMAIL"},

                 {key: 'dataScadenza.date', label: "VISUALIZZ. ENTRO", formatter: 'getDataOraIta'},
                 {key: 'dataChiusura.date', label: "VISUALIZZATO IL", formatter: 'getDataOraIta'}]
    };
  },

  methods: {
    /*loadData() {
      let r;
      r = this.$route.query.id;      
      this.referto = JSON.parse(atob(r));
      this.shareDoc = {"Id": this.referto.Id, "username": this.referto.username};
      console.log(JSON.stringify(this.shareDoc));
      console.log(JSON.stringify(this.referto));       
    },*/
    isPhone(phone) {
      const regExp = /^(\+|\d)[0-9]{7,16}$/g;
      if (phone != null && phone != "") {
        if (regExp.test(phone) === false) {
          return false;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    isEmail(email) {
      const regExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i;
      if (email != null && email != "") {
        if (regExp.test(email) === false) {
          return false;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    inviaReferto() {
      if (this.shareDoc!=null)
      {
        const data={
          telefono:this.docTel,
          email:this.docMail
        }
        apiAxios.post("jit/"+this.urlPath+"/"+encodeURI(btoa(JSON.stringify(this.shareDoc)))+"/share", data)
        .then(response=>{
          const resp=response.data.ShareRefertoResult;
          if (resp.success)
          {
            this.sharedMsg=true;
            this.sharedMsgText="OK";
            this.sharedErr=false;
            this.getRefertiInviati();
          }
          else {
            this.sharedMsg=true;
            this.sharedMsgText=resp.error;
            this.sharedErr=true;
          }
        })
      }
    }, 
    getRefertiInviati() {
      let id;
      id = this.$route.query.id;      
      let r;
      r = JSON.parse(atob(id));
      this.shareDoc = {"Id": r.Id, "username": r.username};


      apiAxios.get("jit/"+this.urlPath+"/shared/"+id)
      .then(response=>{
        const resp=response.data;
        this.sharedDocs = resp.SharedResult.history;
        this.referto = resp.SharedResult.request;
      })

      console.log(JSON.stringify(this.shareDoc));
      console.log(JSON.stringify(this.referto));            
    },    
    tipoReferto(tipo) {
      switch(tipo)
      {
        case "CDIMed-NSI":
          return "MDL";
        case "METAFORA":
          return "Anatomia Patologica";
        case "ESAOTE":
          return "Imaging";
        case "MODULAB":
        case "HDOCS-LAB":
          return "Laboratorio";
        case "HDOCS-AMB":
          return "Poliambulatorio";
        default:
          return "Referto";
      }    
    },
    dataFormatted(data)
    {
      let ff=data.substr(0,10);
      let dataF = new Date(ff);
      dataF = (dataF.getDate()<10 ? "0" : "") + dataF.getDate() + "/" + ((dataF.getMonth()+1)<10 ? "0" : "") + (dataF.getMonth()+1) + "/" + dataF.getFullYear();        
      return dataF;
    },  
    getDataIta(value) {
      if(value!="" && value!=null) {
          let data = new Date(value);
          const options = { 
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit'
                          };
          return data.toLocaleString('it-IT', options);
      }
    },     
    getDataOraIta(value) {
      if(value!="" && value!=null) {
          let data = new Date(value);
          const options = { 
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit',
                              hour: '2-digit',
                              minute: '2-digit'
                          };
          return data.toLocaleString('it-IT', options);            
      }
    },       
  },

  computed: {
    service:function (){
      let msp=this.$route.query.mdl;
      if (msp!=null) {
        if (msp === 'true' || msp==="1") {
          return 'mdl';
        }
      }
      return 'referti';
    },
    urlPath:function(){
      if (this.service==="mdl")
        return "mdl/referti";
      return "referti";
    }
  }  

};

</script>
